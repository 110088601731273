import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

interface AccordionProps {
  children?: React.ReactNode;
}

function Accordion(props: AccordionProps) {
  const { children } = props;

  const [accordionActive, setAccordionActive] = useState(false);

  function handleClick() {
    if (accordionActive === false) {
      setAccordionActive(true);
    } else {
      setAccordionActive(false);
    }
  }

  return (
    <div className={`accordion ${accordionActive ? "active" : ""}`}>
      <button
        type="button"
        onClick={handleClick}
        aria-expanded={accordionActive}
      >
        <span className="mr-2">Om du saknar intyg</span>
        <FontAwesomeIcon icon={solid("angle-down")} />
      </button>
      <div className={`content ${accordionActive ? "open" : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default Accordion;
