// import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spinner from "../../spinner.svg";

interface SpinnerProps {
  label?: string;
}
const Spinner = ({ label }: SpinnerProps) => {
  return (
    <div className="spinner">
      {/* <FontAwesomeIcon spin icon={solid("spinner")} /> */}
      <img src={spinner} alt="Östgötatrafiken skickar-animation" />
      {label && <div>{label}</div>}
    </div>
  );
};

export default Spinner;
