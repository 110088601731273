import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="btn stripped icon-link"
      onClick={(event) => {
        event.preventDefault();
        navigate(-1);
      }}
    >
      <FontAwesomeIcon className="mr-2" icon={solid("arrow-left")} />
      <span>
        Tillbaka
        <span className="show-for-sr">
          {/* {" "}
        till steg {currentStepIndex
          ? currentStepIndex
          : 2} av {numSteps} */}
        </span>
      </span>
    </button>
  );
};
export default BackButton;
