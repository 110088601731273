import React from "react";
import "../styles/App.scss";
import Header from "../components/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
// import { pageCountAtom } from "../App";
// import { useAtom } from "jotai";

function Root() {
  // Count pages WIP
  // const [{ pagePaths }] = useAtom(pageCountAtom);
  // const pageCount = pagePaths.length;

  return (
    <div className="App1">
      <div id="skiplinks">
        <a href="#MainContentArea">Till sidans innehåll</a>
      </div>
      <Header />
      <div id="MainContentArea" className="container pt-6 md:pt-12 pb-6">
        <div id="MainContentArea" className="max-w-3xl">
          <>
            <h1 className="">Ansök om ersättning för sjukresa</h1>
            {/* {pageCount} */}
            <Outlet />
          </>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Root;
