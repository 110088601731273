import { FileHeader } from "./FileHeader";

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
}

export function SingleFileUploadWithProgress({
  file,
  onDelete,
}: SingleFileUploadWithProgressProps) {
  return (
    <>
      <div className="file">
        <FileHeader file={file} onDelete={onDelete} />
      </div>
    </>
  );
}
