import { Formik, Form, Field } from "formik";
import { MedicalTravelModel } from "../types/FormTypes";
import { useAtom } from "jotai";
import { formDataAtom } from "../App";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Box from "../components/Box/Box";
import Progressbar from "../components/Progressbar/Progressbar";
import ErrorList from "../components/Form/ErrorList";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
// import useFocusOnLoad from "../hooks/useFocusOnLoad";

export const FOR_ME = "for-me";
export const FOR_PATIENT = "for-patient";

export default function MedicalTravel() {
  // const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(formDataAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div>
      <Formik<MedicalTravelModel>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });

          navigate("/kontaktuppgifter");
        }}
        initialValues={{
          ...formData,
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          compensationfor: Yup.string().test(
            "at-least-one-radio-selected",
            "Vänligen välj ett svaralternativ för att gå vidare till nästa fråga",
            (value) => {
              return value === FOR_ME || value === FOR_PATIENT;
            }
          ),
        })}
      >
        {({ errors, values }) => (
          <>
            <div className="flex justify-end items-center">
              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                <Progressbar percentage={"0"} />
              </div>
            </div>
            <Form ref={formRef}>
              <Box>
                <h2 tabIndex={-1} id="compensationFor">
                  Vem söker du ersättning för?
                </h2>

                <ErrorList errors={errors} />

                <div
                  role="radiogroup"
                  aria-labelledby="compensationFor"
                  className="radio-group"
                >
                  {errors.compensationfor && (
                    <span
                      aria-describedby="forMe forOther"
                      className="validation-error"
                    >
                      {errors.compensationfor.toString()}
                    </span>
                  )}

                  <div className={errors.compensationfor ? "has-error" : ""}>
                    <Field
                      id="forMe"
                      type="radio"
                      name="compensationfor"
                      value={FOR_ME}
                      checked={values.compensationfor === FOR_ME}
                    />
                    <label htmlFor="forMe">För mig själv</label>
                  </div>

                  <div className={errors.compensationfor ? "has-error" : ""}>
                    <Field
                      id="forOther"
                      type="radio"
                      name="compensationfor"
                      value={FOR_PATIENT}
                      checked={values.compensationfor === FOR_PATIENT}
                    />
                    <label htmlFor="forOther">
                      För patient som jag är vårdnadshavare för
                    </label>
                  </div>
                </div>
              </Box>
              <div className="w-full text-right">
                <button
                  onClick={handleSubmitClick(formRef)}
                  className="btn secondary"
                  type="submit"
                >
                  Gå vidare
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
