import { Field, Form, Formik } from "formik";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import Box from "../components/Box/Box";
import { MedicalVisit, TimePeriod } from "../types/FormTypes";
import * as Yup from "yup";
import Progressbar from "../components/Progressbar/Progressbar";
import { medicalVisitAtom } from "./AddCertificate";
import { MultipleFileUploadField } from "../components/FileUpload/MultipleFileUploadField";
import Textarea from "../components/Form/Textarea";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import { useIsAddMoreTrue } from "./AddMore";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export default function ByBoth() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAddMoreTrue] = useIsAddMoreTrue();

  return (
    <div>
      <Formik<MedicalVisit>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          navigate("../kostnader-for-overnattning");
        }}
        initialValues={{
          ...formData,
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          travelDescription: Yup.string().required(
            "Vänligen ange var du reste till och från med bil"
          ),
          dateTo: Yup.string()
            .required("Vänligen ange datum")
            .isOccurredDate()
            .isDateNoEarlierThan(1, TimePeriod.Year),
          dateFrom: Yup.string()
            .required("Vänligen ange datum")
            .isOccurredDate()
            .isDateSameOrAfter(
              "dateTo",
              "Datum från kan inte vare tidigare än datum till"
            ),
          receipts: Yup.array().min(
            1,
            "Vänligen bifoga ett intyg innan du går vidare."
          ),
        })}
      >
        {({ errors, touched, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>
              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                {isAddMoreTrue ? (
                  <Progressbar percentage={"84"} />
                ) : (
                  <Progressbar percentage={"56"} />
                )}
              </div>
            </div>
            <Form ref={formRef}>
              <Box>
                <>
                  <h2 ref={h2Ref} tabIndex={-1}>
                    Om resan
                  </h2>

                  <ErrorList errors={errors} />

                  <h3>
                    Resa med både privatbil och kollektivtrafik, taxi eller flyg
                  </h3>
                  <ul className="mb-10">
                    <li>
                      Du behöver inte bifoga något kvitto eller ange sträcka. Vi
                      beräknar längden på resan från den adress som du är
                      folkbokförd på, till adressen för vårdenheten du besökt.
                      Uppgifterna hittar vi eftersom du angett personnummer och
                      från det intyg du bifogat.
                    </li>
                    <li>
                      Du betalar alltid egenavgiften, 120 kronor per enkelresa,
                      oavsett hur du reser. Det är kostnaderna utöver det som du
                      kan få ersättning för.
                    </li>
                    <li>
                      Resa med egen bil ersätts med 1,85 kronor per kilometer.
                    </li>
                  </ul>

                  <div className="mb-6">
                    <div className="mb-4 md:mb-0">
                      <Textarea
                        name="travelDescription"
                        currentText={values.travelDescription}
                        label={
                          "1. Ange var du reste till och från med bil (Obligatorisk)"
                        }
                      />
                    </div>
                  </div>
                  <h3 className="mb-6">
                    2. Ange datum när du reste med bil (Obligatorisk)
                  </h3>
                  <div className="md:flex mb-10 md:w-11/12">
                    <div className="mb-6 md:mb-0 md:mr-8 md:w-1/2">
                      <label htmlFor={"dateTo"}>
                        <span>Till Vårdenhet (Obligatorisk)</span>
                        {touched.dateTo && errors.dateTo && (
                          <span className="validation-error">
                            {errors.dateTo.toString()}
                          </span>
                        )}
                      </label>
                      <Field type="date" name="dateTo" id="dateTo" />
                    </div>
                    <div className="md:w-1/2">
                      <label htmlFor={"dateFrom"}>
                        <span>Från Vårdenhet (Obligatorisk)</span>
                        {touched.dateFrom && errors.dateFrom && (
                          <span className="validation-error">
                            {errors.dateFrom.toString()}
                          </span>
                        )}
                      </label>
                      <Field type="date" name="dateFrom" id="dateFrom" />
                    </div>
                  </div>
                  <div>
                    <MultipleFileUploadField
                      currentFiles={values.receipts}
                      multiple={true}
                      label="3. Bifoga kvitton eller biljetter för din resa med kollektivtrafik, taxi eller flyg  (Obligatorisk)"
                      inputId="receipts"
                      inputName="receipts"
                    />
                  </div>
                </>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
