import { Field, useFormikContext } from "formik";
import { useRef, useState, forwardRef, ForwardedRef } from "react";
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea";
import { MedicalVisit } from "../../types/FormTypes";

interface TextareaProps {
  name: keyof MedicalVisit;
  maxLength?: number;
  currentText: string;
  label: string;
}

const Textarea = forwardRef(
  (
    { name, maxLength = 500, currentText, label }: TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const { values, errors, touched, setFieldValue } =
      useFormikContext<MedicalVisit>();
    const [remainingChars, setRemainingChars] = useState(
      maxLength - (currentText?.length ?? 0)
    );
    const [text, setText] = useState(currentText ?? "");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      const val = event.target?.value;

      if (value.length <= maxLength) {
        setFieldValue(name, value);
        setText(val);
        setRemainingChars(maxLength - value.length);
      }
    };

    useAutosizeTextArea(textAreaRef.current, text);

    return (
      <>
        <label htmlFor={name} className="h3 textarea-label">
          {label}
          {touched[name] && errors[name] && (
            <span className="validation-error">
              <>{errors[name]}</>
            </span>
          )}
        </label>
        <div className="text-right remaining-chars">
          {remainingChars}/{maxLength} tecken
        </div>
        <Field
          className={`w-full ${
            errors[name] && touched[name] ? "has-error" : ""
          } `}
          name={name}
          id={name}
          as="textarea"
          value={values[name]}
          onChange={handleChange}
          innerRef={textAreaRef}
        />
      </>
    );
  }
);

export default Textarea;
