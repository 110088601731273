import { Form, Formik } from "formik";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import Box from "../components/Box/Box";
import { MedicalVisit } from "../types/FormTypes";
import Progressbar from "../components/Progressbar/Progressbar";
import { medicalVisitAtom } from "./AddCertificate";
import { MultipleFileUploadField } from "../components/FileUpload/MultipleFileUploadField";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import Textarea from "../components/Form/Textarea";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useIsAddMoreTrue } from "./AddMore";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export default function OtherUploads() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAddMoreTrue] = useIsAddMoreTrue();

  return (
    <div>
      <Formik<MedicalVisit>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          navigate("../fler-resor");
        }}
        initialValues={{
          ...formData,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ errors, touched, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                {isAddMoreTrue ? (
                  <Progressbar percentage={"84"} />
                ) : (
                  <Progressbar percentage={"70"} />
                )}
              </div>
            </div>
            <Form ref={formRef}>
              <Box>
                <>
                  <h2 ref={h2Ref} tabIndex={-1}>
                    Övriga kostnader
                  </h2>

                  <ErrorList errors={errors} />

                  {/* <h3>Intyg för vårdbesök från vårdgivare</h3> */}

                  <p className="mb-10">
                    I vissa fall kan kostnader för exempelvis övernattning
                    ersättas. Läs mer om vad du kan få ersättning för på{" "}
                    <Link target="_blank" to="https://www.1177.se/">
                      1177.se{" "}
                      <FontAwesomeIcon
                        icon={solid("external-link")}
                        className="ml-2"
                      />
                    </Link>
                  </p>

                  <div className="mb-10">
                    <MultipleFileUploadField
                      currentFiles={values.otherFiles}
                      multiple={true}
                      label="Bifoga kvitto för övriga kostnader (frivilligt)"
                      inputId="otherFiles"
                      inputName="otherFiles"
                    />
                  </div>

                  <div>
                    <Textarea
                      name="comments"
                      maxLength={5000}
                      currentText={values.comments}
                      label={"Övriga kommentarer (frivilligt)"}
                    />
                  </div>
                </>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
