interface ProgressbarProps {
  percentage: string;
}

const Progressbar = ({ percentage }: ProgressbarProps) => {
  return (
    <>
      <div className="progress-container" aria-hidden="true">
        <div className="inner">
          <span
            style={{ width: `${percentage}%` }}
            className="progress-line"
          ></span>
          <progress id="formProgress" value={percentage} max="100">
            {percentage}%
          </progress>
        </div>
        <span className="right-0 progress-text">{percentage}%</span>
      </div>
    </>
  );
};

export default Progressbar;
