import React from "react";

interface BoxProps {
  children?: React.ReactNode;
  fullwidth?: boolean;
}

function Box(props: BoxProps) {
  const { children, fullwidth } = props;

  return (
    <div className="box">
      <div className={`inner ${fullwidth ? "fullwidth" : ""}`}>{children}</div>
    </div>
  );
}

export default Box;
