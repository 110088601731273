import { Link } from "react-router-dom";
import logo from "../../logo.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="container md:flex-row flex-col mx-auto pt-8 pb-2 md:pt-20 md:pb-7 flex justify-between items-start md:items-center">
        <div className="mb-4 md:mb-0">
          <Link
            className="block mb"
            to={
              "https://www.ostgotatrafiken.se/sa-reser-du-med-oss/bestallningstrafik/fardtjanst--sjukresor/sjukresor-till-annan-region/"
            }
          >
            <span className="icon-link block">
              <i
                className="top-0.5 relative ogt-arrow-right-32 flip mr-2 icon--big icon--primary"
                aria-hidden="true"
              ></i>
              Tillbaka till Sjukresor till annan region
            </span>
          </Link>
        </div>
        <Link className="block" to={`/`}>
          <img src={logo} className="logo" alt="logo" />
        </Link>
        {/* <div>
          <Link className="block" to={`/translate`}>
            <span className="icon-link block translate">
              <i
                className="ogt-translate mr-2 icon--big icon--primary relative"
                aria-hidden="true"
              ></i>
              Translate
            </span>
          </Link>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
